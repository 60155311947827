import React from "react";
import GoogleMapReact from "google-map-react";

const Marker = ({ text }) => <div className="maps marker">{text}</div>;

export default function Map() {
  const defaultProps = {
    center: {
      lat: 19.803582,
      lng: -99.096294,
    },
    zoom: 15,
  };

  return (
    <div className="maps">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDJFt7VLNl7zns9FR9I4Val7NLY43EKBJs" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <Marker lat={19.803582} lng={-99.096294} text="Electricazz" />
      </GoogleMapReact>
    </div>
  );
}
