import { Link } from "gatsby";
import React from "react";
import catalog from "./content/catalog.json";

export default {
  designation: {
    description: "Compras y ventas de autos elctricos al por menor",
    lang: "es",
    meta: [],
    keywords: [
      "electricaaz",
      "moto",
      "auto electrico",
      "moto eléctrica",
      "motos electricas en mexico",
    ],
    title: "Electricaaz",
    data: {
      logo: "/images/logo.png",
      siteName: "Electricaaz",
      siteDescription: catalog.models.description,
      twitterHandle: "",
    },
  },
};
