import React from "react";
import PropTypes from "prop-types";
import "bootstrap/dist/css/bootstrap.css";

import JSONData from "../../content/data.json";

import Header from "./header";
import Footer from "./footer";

import "../css/style.css";
import "../css/font-awesome.css";

if (typeof window !== "undefined") {
  require("smooth-scroll")('a[href*="#"]');
}

export default function Layout({ children, isHome }) {
  return (
    <>
      {isHome || (
        <Header data={JSONData} siteTitle={JSONData.siteName} isHome={isHome} />
      )}
      <div>
        <main id="home">{children}</main>
      </div>
      <Footer siteName={JSONData.siteName} />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
