import { Link } from "gatsby";
import React, { useState } from "react";
import text from "../../content/texts.json";

export default function Header({ data, isHome = false }) {
  const [menu, setMenu] = useState(false);
  return (
    <header className={`site-header ${menu ? "active" : ""}`}>
      <div className="container">
        <div className="header-main">
          <div className="logo">
            <Link to="/">
              {data.logo.file.url ? (
                <img src="/images/logo.png" alt="logo" />
              ) : (
                <span>{data.siteName}</span>
              )}
            </Link>
          </div>
          <div
            className="responsive-menu"
            onClick={() => {
              setMenu(!menu);
            }}
          >
            <span></span>
          </div>

          <div className="menu">
            <ul
              onClick={() => {
                setMenu(false);
              }}
            >
              {isHome || (
                <li>
                  <Link to="/">{text.sections.home}</Link>
                </li>
              )}
              <li>
                <Link to="/#catalog">{text.sections.catalog}</Link>
              </li>
              <li>
                <Link to="#contact">{text.sections.contact}</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}
